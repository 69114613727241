import React, { Fragment } from 'react'

const LoadingBox = ({text}) => {
  return (
    <Fragment>
      <div className="loading-box">
        <img className="loading-main" src="/loading.png" alt="Loading icon" />
      </div>
      <p>{text}</p>
    </Fragment>
  )
}

export default LoadingBox