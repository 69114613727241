import React, {useState, Fragment} from 'react'
import Layout from '../../components/layout'
import Validate from '../../components/validate'
import Nav from '../../components/ui/nav'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'
import ComboBox from 'react-responsive-combo-box'
import Footer from '../../components/landing/footer'
import { decodeCookie } from '../../utils/constants.js'

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
  lambdaUri = 'https://www.paverops.com'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
  lambdaUri = `https://stage--paverops.netlify.app`
}

// markup
const Feedback = () => {
  let [supportText, setSupportText] = useState("")
  let [submitProgress, setSubmitProgress] = useState(false)
  let [reason, setReason] = useState("Bug Report")
  let [priority, setPriority] = useState("Low")

  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])
  // Safety check so we don't crash the app
  if (!cookies.paverReg){
    cookies.paverReg = {}
  } else {
    // Decode the result
    cookies.paverReg = decodeCookie(cookies.paverReg)
  }

  return (
    <Layout>
      <main>
        <Validate lambdaUri={lambdaUri} redirectUri={redirectUri}>
          <div className="non-landing">
            <Nav page={"support"} items={[
              {url: "/support/", text: "Support Hub"},
              {url: "/app/", style: "border", text: "App Home"}
            ]} />
            <div className="topper">
              <div className="img-wrapper">
                <img src="/images/toppersupport.png" alt="An angular slice of a road being repaired with heavy machinery" />
              </div>
              <div className="deck-wrapper">
                <div className="deck">
                  <h1>
                    Technical Support
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <section className="app-body support">
            <div className="support-container">
              <p className="email-notice"><strong>Thank you for using PaverOps. Please submit your bug details or feedback in the form below and our team will respond soon.</strong></p>

              <p className="email-notice">We will use the email address linked to your Esri account to reply ({cookies.paverReg.email}).</p>
              <div className="support-form-wrapper">
                <label htmlFor="support-reason">Topic:</label>
                <div className="field-box">
                  <ComboBox 
                    id="support-reason"
                    options={[
                      "Bug Report",
                      "Technical Support Request",
                      "General Feedback",
                      "Question Regarding Account",
                      "Other Message"
                    ]}
                    editable={false}
                    onSelect={(option) => {
                      setReason(option)
                    }}
                    defaultValue={reason}
                    className="combo"
                  />
                </div>
                <label htmlFor="support-priority">Priority:</label>
                <div className="field-box">
                  <ComboBox 
                    id="support-priority"
                    options={[
                      "Low",
                      "Medium",
                      "High"
                    ]}
                    editable={false}
                    onSelect={(option) => {
                      setPriority(option)
                    }}
                    defaultValue={priority}
                    className="combo"
                  />
                </div>
                <label htmlFor="support-text">Message:</label>
                <textarea id="support-text" value={supportText} disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
                  setSupportText(e.currentTarget.value)
                }}>
                </textarea>
                <button className="primary" onClick={() => {
                  // Exit early if we're already in progress
                  if (!submitProgress){
                    setSubmitProgress("progress")
                    // Get form data ready to send
                    let formData = new FormData()
                    formData.append('username', cookies.paverReg.username)
                    formData.append('org', cookies.paverReg.org)
                    formData.append('email', cookies.paverReg.email)
                    formData.append('report', supportText)
                    formData.append('reason', reason)
                    formData.append('priority', priority)
                    if (typeof navigator !== "undefined"){
                      formData.append('useragent', navigator.userAgent)
                    }
                    
                    // Send the support req over
                    fetch(lambdaUri+'/.netlify/functions/send-email', {
                      method: 'post',
                      body: formData
                    })
                    .then(response => response.json())
                    .then(data => {
                      // Just set to done so we can't be spammed with requests
                      setSubmitProgress("done")
                      // Prep next message
                      setTimeout(() => {
                        setSubmitProgress("next")
                      }, 2000)
                    })
                  } else if (submitProgress === "next") {
                    // Handle clearing the content for a new message
                    setSupportText("")

                    // Ready to write again
                    setSubmitProgress(false)
                  }
                }}>
                  {submitProgress ? (
                    <Fragment>
                      {submitProgress === "progress" &&
                        <span className="loading">
                          <img src="/loading.png" alt="Loading icon" />
                        </span>
                      }
                      {submitProgress === "done" &&
                        <span>Feedback sent!</span>
                      }
                      {submitProgress === "next" &&
                        <span>Write new message</span>
                      }
                    </Fragment>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
                {submitProgress === "next" &&
                  <Fragment>
                    <button className="primary" onClick={() => {
                      navigate("/app/")
                    }}>
                      Return to map
                    </button>
                  </Fragment>
                }
              </div>
            </div>
          </section>
          <Footer />
        </Validate>
      </main>
    </Layout>
  )
}

export default Feedback

